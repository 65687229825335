<template>
  <div>
    <Headers :viewParams="viewParams" />
      <div class="content-page market-area-rs" v-if="no_access==1"><div class="content no_premision" v-html="lan.no_premision"></div></div>
     <div class="content-page market-area-rs" v-if="no_access==0">
      <div class="content">
      <div class="row">
        <div class="col-xl-6">
            <div class="card-box pb-2 ribbon-box">
                <!-- <div class="ribbon-two ribbon-two-success"><span>8月以後</span></div> -->
                <h3 class="header-title mb-3">{{area_name}}</h3>
                <div class="row mt-3" style="height:540px;overflow:hidden" >
                    <iframe style="height: 610px;width: 100%;margin: 0 auto; border:0; margin-top:-81px"
                                        :src="area_map[cur_lan]"
                                            >
                                        </iframe>
                </div> <!-- end col -->
            </div>
        </div>
        <div class="col-xl-6">
            <div class="card-box pb-2 ribbon-box">
               <div class="tab-box" style="height:580px;">
                  <ul class="tab-head nav nav-tabs">
                      <li class="nav-item active" data-id="tc1" v-if="show_rate"><a href="#tc1" data-toggle="tab" aria-expanded="true" class="nav-link active" @click="loadiframe(1)"><div class="lang" v-html="lan.vacancy_rate"></div></a></li>
                      <li class="nav-item" data-id="tc2"><a href="#tc2" data-toggle="tab" aria-expanded="false" class="nav-link" @click="loadiframe(2)"><div class="lang" v-html="lan.prime_rent"></div></a></li>
                  </ul>
                   <div class="help" v-if="cur_lan=='jp'"><img :src="'../static/help.svg'" @click="isPopupHelp=true"></div><transition name="fade">
          <div v-show="isPopupHelp" class="modal popup-modal">
            <div class="modal-dialog">
              <div class="popup modal-content help-box">
                <div class="modal-header">
                  <button type="button" class="close" @click="isPopupHelp = false">
                    <span>×</span>
                  </button>
                </div>
                <div class="modal-body" v-if="isPopupHelp">
               
                <div v-html="lan.help_chart"></div>
                </div>
              </div>
            </div>
            <div class="popup-bg" @click="isPopupHelp = false"></div>
          </div>
        </transition>
                  <div class="embed-responsive embed-responsive-16by9 tab-pane show active tc1" style="height:500px" id="tc1">
                      <iframe class="embed-responsive-item" 
                      :src="cur_iframe"
                          >
                      </iframe>
                        
                  </div>
                
              </div>
            </div>
        </div>
      </div>
      
     
      <div class="row">
        <div class="col-12">
          <div class="card-box">
            
             <h3 class="header-title mb-3" style="display:inline-block">
              <div class="lang" v-html="lan.market_data"></div>
            </h3>
            <div style="float:right;display:inline-block;width:320px;text-align: right;"><div class="lang" v-html="lan.download"></div>：&nbsp;&nbsp;<button type="button" @click="downloadData('shift-jis')" class="btn btn-blue waves-effect waves-light market-data-btn" ><div class="lang" v-html="lan.windows"></div></button>
              <button type="button" @click="downloadData('utf-8')" class="btn btn-blue waves-effect waves-light market-data-btn" style="margin-left: 15px;">&nbsp;&nbsp;<div class="lang" v-html="lan.others"></div>&nbsp;&nbsp;</button>
              </div>
            <div class="table-rep-plugin">
              <div class="table-responsive">
                <table class="table table-striped focus-on" :style="{width:(tableHeaders.length*120+330)+'px'}">
                  <thead>
                    <tr >
                      <th style="width:170px;
    position: absolute;z-index:9;background: #fff;"></th>
                      <th style="width:160px;left: 205px;
    position: absolute;;z-index:9;background: #fff;"></th>
                      <th v-for="tableHeader in tableHeaders" :key="tableHeader" :class="'table-row-' + tableHeader" >{{ tableHeader }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="listData.length>0">
                    <tr><td>
                    <span class="sp1" v-for="(obj, idNum) in listData" :key="idNum"  >
                      <span class="spt"><span class="spt2" v-html="obj.title[cur_lan].split('_')[0].replace('（円/坪）','<br>（円/坪）').replace('(Tsubo)','<br>(Tsubo)').replace('（坪）','<br>（坪）').replace('(%)','<br>(%)').replace('（％）','<br>（％）').replace('(JPY/Tsubo)','<br>(JPY/Tsubo)')" ></span><button type="button" @click="showpop(idNum)" class="btn btn-blue waves-effect waves-light market-data-btn" style="padding:5px;"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bar-chart-2 icon-dual"><line x1="18" y1="20" x2="18" y2="10"></line><line x1="12" y1="20" x2="12" y2="4"></line><line x1="6" y1="20" x2="6" y2="14"></line></svg></button>
                      
                      </span>
                    <div v-show="isPopup[idNum]" class="modal popup-modal">
                              <div class="modal-dialog">
                                <div class="popup modal-content">
                                  <div class="modal-header">
                                    <button type="button" class="close" @click="isPopup[idNum] = false">
                                      <span>×</span>
                                    </button>
                                  </div>
                                  <div class="modal-body w850" v-if="isPopup[idNum]">
                                    <div  class="col-12"  >
                                      <apexchart  :type="'line'" :height="650" :options="obj.chart_options" :series="obj.chart_series"></apexchart>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="popup-bg" @click="isPopup[idNum] = false"></div>
                            </div>
                      <span  class="sp2" v-for="(obj2, idNum2) in obj.data" :key="idNum2" >
                          <span :class="{'sp3':issp,'curen':cur_lan=='en','red':obj3<0}"  v-for="(obj3, idNum3) in obj2" :key="idNum3">
                          <span  v-if="idNum3>1">{{(parseFloat(obj3)!=NaN && idNum3>1 ? formart_money(obj3,obj.title['jp'].indexOf('空室率')>-1 || obj.title['jp'].indexOf('プライムイールド')>-1 ?'':'int'):obj3)}}</span>
                           <span v-if="idNum3<2">{{obj.title[cur_lan].split("_")[1]}}</span>
                          </span>
                      </span>
                    </span>
                    </td>
                    </tr>
                    <tr class="p-2" v-if="!listData.length">
                      <td><code ><div class="lang" v-html="lan.no_data"></div></code></td>
                    </tr>
                </tbody>
                </table>
              </div> <!-- end .table-responsive -->
            </div> <!-- end .table-rep-plugin-->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6">
          <div class="card-box">
             <div class="tab-box" style="height:580px;">
                      <ul class="tab-head nav nav-tabs">
                          <li class="nav-item active" data-id="mnsp1"><a href="#mnsp1" data-toggle="tab" aria-expanded="true" class="nav-link active" @click="loadiframe('mnsp1')"> <div class="lang" v-html="lan.major_new_supply_projects"></div></a></li>
                          <li class="nav-item" data-id="mnsp2" v-if="1>2"><a href="#mnsp2" data-toggle="tab" aria-expanded="false" class="nav-link" @click="loadiframe('mnsp2')"><div class="lang" v-html="lan.creis_office_new_demand_map"></div></a></li>
                      </ul>
                                
                      <div class="tab-content">
                      <div class="embed-responsive tab-pane show active mnsp1"  id="mnsp1">
                         <div class="table-responsive2">
                          <table class="table table-striped focus-on">
                            <thead>
                              <tr>
                                <th style="width:40%"><div class="lang" v-html="lan.name"></div></th>
                                <th style="width:32%"><div class="lang" v-html="lan.location"></div></th>
                                <th style="width:16%"><div class="lang" v-html="lan.completion"></div></th>
                                <th style="width:12%"><div class="lang" v-html="lan.gfa_"></div></th>
                                
                              </tr>
                            </thead>
                            <tbody style="max-height:580px;">
                              <tr class="" v-for="(obj, idNum) in listDataMNSP" :key="idNum"  >
                              <td :title="idNum2<4 ? obj2:''" :class="{'hide':(cur_lan!='en' && (idNum2==1 || idNum2==3)) || (cur_lan!='jp' && (idNum2==0 || idNum2==2)) || idNum2==5 || idNum2>6 }" v-for="(obj2, idNum2) in obj" :key="idNum2" :style="idNum2==6 ?'text-align:right !important;padding-right: 20px !important;':''">  
                                {{(idNum2==6 ? formart_money(obj2,'int'):obj2)}}
                              </td>
                              </tr>
                              </tbody>
                              <tbody v-if="!listDataMNSP.length">
                                <tr class="p-2 no-data" >
                                  <td colspan="4"><code ><div class="lang" v-html="lan.no_data"></div></code></td>
                                </tr>
                              </tbody>
                          </table>
                          <span><div class="lang" v-html="lan.area_unit"></div></span>
                        </div> <!-- end .table-responsive -->
                            
                      </div>
                      <div class="embed-responsive tab-pane mnsp2"   id="mnsp2">
                          <div id="map_mnsp" class="google_maps"></div>
                          
                      </div>
                  </div>
              </div>
            
          </div>
        </div>
        <div class="col-xl-6">
          <div class="card-box">
            <div class="tab-box" style="height:580px;">
                      <ul class="tab-head nav nav-tabs">
                          <li class="nav-item active" data-id="mfp1"><a href="#mfp1" data-toggle="tab" aria-expanded="true" class="nav-link active" @click="loadiframe('mfp1')"> <div class="lang" v-html="lan.major_future_projects"></div></a></li>
                          <li class="nav-item" data-id="mfp2" v-if="1>2"><a href="#mfp2" data-toggle="tab" aria-expanded="false" class="nav-link" @click="loadiframe('mfp2')"><div class="lang" v-html="lan.creis_office_new_demand_map"></div></a></li>
                      </ul>
                      <div class="tab-content">
                      <div class="embed-responsive tab-pane show active mfp1"  id="mfp1">
                          <div class="table-responsive2">
                            <table class="table table-striped focus-on">
                              <thead>
                                <tr>
                                  <th style="width:40%"><div class="lang" v-html="lan.name"></div></th>
                                  <th style="width:32%"><div class="lang" v-html="lan.location"></div></th>
                                  <th style="width:16%"><div class="lang" v-html="lan.completion"></div></th>
                                  <th style="width:12%"><div class="lang" v-html="lan.gfa_"></div></th>
                                </tr>
                              </thead>
                              <tbody  style="max-height:580px;">
                                <tr class="" v-for="(obj, idNum) in listDataMFP" :key="idNum" >
                                <td :title="idNum2<4 ? obj2:''" :class="{'hide':(cur_lan!='en' && (idNum2==1 || idNum2==3)) || (cur_lan!='jp' && (idNum2==0 || idNum2==2)) || idNum2==5 || idNum2>6 }" v-for="(obj2, idNum2) in obj" :key="idNum2" :style="idNum2==6 ?'text-align:right !important;padding-right: 20px !important;':''">  
                                  {{( idNum2==6 ? formart_money(obj2,'int'):obj2)}}
                                </td>
                                </tr>
                                </tbody>
                              <tbody v-if="!listDataMFP.length">
                                <tr class="p-2 no-data" >
                                  <td colspan="4"><code ><div class="lang" v-html="lan.no_data"></div></code></td>
                                </tr>
                              </tbody>
                            </table>
                            <span><div class="lang" v-html="lan.area_unit"></div></span>
                          </div> <!-- end .table-responsive -->
                            
                      </div>
                      <div class="embed-responsive  tab-pane mfp2"   id="mfp2">
                          <div id="map_mfp" class="google_maps"></div>
                          
                      </div>
                  </div>
              </div>
           </div>
        </div>
      </div>
     
      <!--<div class="row mt-3">
       <div class="col-xl-12">
          <div class="card-box area_profile">
            <div class="left-area"><view v-if="creis_area_total>0" v-html="lan.area_total.replace('{year}',creis_cur_quarter.substr(0,4)).replace('{area_total}',Math.round(creis_area_total/(cur_lan=='en' ?1000:10000)))"></view>
            </div>
            <div class="right-area" v-if="area_description.description[cur_lan]!=null && area_description.description[cur_lan]!=''"><h3><div class="lang" v-html="lan.area_profile"></div></h3><br>
            {{area_description.description[cur_lan]}}</div>
             </div> 
        </div>
      </div>-->
    </div>
    </div>
    <Footer />
  </div>
</template>
<style lang="scss">
.area_profile{display: inline-block;width: 100%;}
.left-area{width: 18%;float:left;padding: 1%;
}
.left-area button{
  
  margin-top: 20px;
}
.right-area{width: 78%;float:left;padding: 1%;}
.area-btn{margin-top:20px ; border-top:2px solid #003f2d ;}
.no_premision{    background-color: #fff;
    padding: 1.5rem;
    box-shadow: 0 0.75rem 6rem #efefef;
    margin-bottom: 24px;
    border-radius: .25rem; text-align: center;}
.market-area-rs{ 
  .no-data code{width: 100%;
    display: table;
    text-align: center;}
  .table-responsive{overflow-x: scroll;}
  .red{color: #ff0000;}
  .table-responsive th, span{text-align: center;display: block;float: left;}
  .sp2 span,.table-responsive th{width: 120px;}
  .table-responsive{border: 0;width: auto;}

  .table thead th{height: 45px;    padding: .85rem 0 .85rem .85rem !important;text-align: right !important; border:0}
  .tab-content{    height: 540px;
      overflow-y: auto;}
  .table td{padding: 0 !important;  border: 0;
      background: #fff;}
  .sp3{border-bottom:1px solid #ccc;height: 40px;display: table-cell; text-align: right;
      vertical-align: middle;}
      .sp3 span{
        text-align: right;
      }
  .feather-bar-chart-2{color:#fff;border: 0;}
  .spt{width: 170px; border-bottom:1px solid #ccc; border-right:1px solid #ccc; display: table;position: relative;
  line-height: 19px;
  min-height: 40px;
    }
    .spt button{position: absolute;
    top: 5px;
    right: 10px;}

  .spt2{
    position: relative;
    display: table-cell;
    width: auto;
        line-height: 19px;
      vertical-align: middle;
          float: none;
     padding-right: 45px;
  }
  .sp1{clear: both;width: auto;height: 40px;line-height: 40px;}
   .sp1:nth-child(2) .spt, .sp1:nth-child(5) .spt{
    display: none;
  }

  .sp1:nth-child(1) .spt,.sp1:nth-child(4) .spt{
    height: 80px;
  }
  .sp1:nth-child(1) .spt button,.sp1:nth-child(4) .spt button{margin-top:25px;}
  .spt{position: absolute;z-index:9; background: #fff;}
  .sp2{width: auto;margin-left: 330px;}
  .market-data-btn{    display: inline-flex;}
  .market-data-btn svg{margin-right: 5px;}
  .outer{background:#c0d4cb40;}
  .sp2 .sp3:nth-child(1){width: 150px;display: none; position: absolute;z-index:8;    left: 206px; background: #fff; border-right:1px solid #ccc;}
  .sp2 .sp3:nth-child(2){width: 160px;position: absolute;z-index:8;     left: 206px; background: #fff; border-right:1px solid #ccc;}
  .sp2 .sp3:nth-child(1) span,.sp2 .sp3:nth-child(2) span{width: 170px;  text-align: left; padding-left: 20px;}
  .sp2 .curen:nth-child(1){width: 160px;display: block;}

  .sp2 .curen:nth-child(2){width: 160px;display: none;}
  .popup-modal {
    display: block;
  }
  .popup-bg {
    background: rgba(0, 0, 0, .25);
    height: 100vh;
    left: 0;
    top: 0;
    position: fixed;
    width: 100vw;
    z-index: 9;
  }

  .popup {
    background: #fff;
    border-radius: .15rem;
    box-shadow: 0 .15rem .5rem rgba(0, 0, 0, .25);
    left: 50% !important;
    max-height: 85vh;
    min-height: 750px;
    width: 850px;
    max-width: 90%;
    position: fixed;
    
    transform: translateX(-50%) !important;
    z-index: 16;
  }

  .popup iframe {
    width: 100%;
  }

  /*  */
  .table-responsive2 table{ table-layout: fixed;}
  .table-responsive2 .table thead th,.table-responsive2 td{text-align: left !important;padding-left:0px !important;  overflow:hidden;
              text-overflow:ellipsis;
              white-space:nowrap;}

              .table-responsive2 td{border-bottom: 1px solid #ccc;height: 30px;line-height: 30px;}
  .hide{display:none}
  .table-responsive2 tr:nth-child(even) td{background: #F5F6F8 !important;}
  .table-striped tr th:nth-child(3){margin-left:330px;}
  .google_maps {
    height: 520px;
  }
    .help{    position: absolute;
    top: 30px;
    right: 3%; display: inline-block;}
    .help-box{margin-top:10%;width: 40%}
}
</style>
<script>
import Headers from '@/components/Headers.vue'
import Footer from '@/components/Footer.vue'
import {getNdate} from '@/components/common'
import axios from 'axios'
import { inject } from 'vue';
export default {
  name: 'areaList',
  components: {
    Headers,Footer
  },
  data() {
    return {
        no_access:1,
        creis_language_data:{},
        tableHeaders:[],
        listData:[],
        isPopup:[],
        isPopupHelp:false,
        frame_id:0,
        area_map:{"en":"","jp":""},
        cur_iframe:'',
        issp:true,
        beginQuarter:this.dateformat('start'),
        endQuarter:this.dateformat('end'),
        area:'yokohama',
        child_area: '',
        parent_area: '',
        area_name:'Yokohama',
        area_name_en:'',
        area_name_en2:'',
        area_name_jp:'',
        child_area_name_en:'',
        chart_rs_rent:{"en":"","jp":""},
        chart_rs_vacancy_rate:{"en":"","jp":""},
        cur_lan:localStorage.getItem('cur_lan') !="en" ? "jp":"en",
        listDataMNSP:[],
        listDataMFP:[],
        //mapList:[],
        lan:inject('global').creis_language_data,
        map:[null,null],
        mapContainer:[null,null],
        markers:[],
        infowindow:[],
        google: {
          maps: {
            StreetViewPanorama: {},
            Map: {},
            MapTypeId: {},
            Marker:{},
            InfoWindow:{},
            Polygon:{},
          },
        },
      show_rate:0,
      chart_polygon:null,
      area_description:{total:{en:'',jp:''},description:{en:'',jp:''}},
      cur_quarter_index:0,
      creis_cur_quarter:'',
      creis_area_total:0,
      current_gps:'',
    }
  },
   mounted() {
    
    let creis_language_data=JSON.parse(localStorage.getItem('creis_language_data'));
    this.cur_lan=localStorage.getItem('cur_lan') !="en" ? "jp":"en";
    this.lan=creis_language_data['menu_data_'+this.cur_lan]
    if(localStorage.getItem('uinfo')!=''){
      let uinfo=JSON.parse(localStorage.getItem('uinfo'))
     
      if((!uinfo['リテールマーケット']  || uinfo['状態']!=1 ||  uinfo['契約期限 終了日']==null || uinfo['契約期限 終了日']<getNdate() || ((uinfo['paymentConfirmDay']==null || uinfo['paymentConfirmDay']=='') && uinfo['dueToPaymentDay']<getNdate()) || uinfo['showStep6']==1 || uinfo['step'] <= '6') && uinfo['group']!='cbredata user' && localStorage.getItem('groups') != 'admin'){
        this.no_access=1;
        if (((!uinfo['インダスマーケット'] && !uinfo['オフィスマーケット'] && !uinfo['リテールマーケット']) || uinfo['契約期限 終了日'] == null || uinfo['契約期限 終了日'] < getNdate() || ((uinfo['paymentConfirmDay'] == null || uinfo['paymentConfirmDay'] == '') && uinfo['dueToPaymentDay'] < getNdate()) || uinfo['showStep6'] == 1 || uinfo['step'] <= '6') && uinfo['group']!='cbredata user'  && localStorage.getItem('groups')!='admin'){
          window.location.href='/userApply'
        }
      }else{
          this.no_access=0;
      }
    }else{
      this.no_access=1;
    } 
    this.area=this.$route.query.area!=undefined  ? this.$route.query.area:'';
    this.parent_area=this.$route.query.parent_area!=undefined  ? this.$route.query.parent_area:'';
    this.child_area=this.$route.query.child_area!=undefined  ? this.$route.query.child_area:'';

   
    this.area_name_en=this.$route.query.area_name_en!=undefined  ? this.$route.query.area_name_en:'yokohama';
    this.area_name_en2=this.$route.query.area_name_en2!=undefined  ? this.$route.query.area_name_en2:'yokohama';
    this.area_name_jp=this.$route.query.area_name_jp!=undefined  ? this.$route.query.area_name_jp:'yokohama';
    this.area_name=this.cur_lan=='jp' ? this.area_name_jp:this.area_name_en2;
    this.child_area_name_en=this.$route.query.child_area_name_en!=undefined  ? this.$route.query.child_area_name_en:'yokohama';
   
  this.getRSQuarterlyReports();
  this.loadGoogleMapsScript().then(() => {
      this.google = window.google;
      //this.initializeMap();
    });
  },
  
watch:{
  $route(){
    
    this.cur_lan=localStorage.getItem('cur_lan') !="en" ? "jp":"en";
    let creis_language_data=JSON.parse(localStorage.getItem('creis_language_data'));
    this.lan=creis_language_data['menu_data_'+this.cur_lan]
    if(localStorage.getItem('uinfo')!=''){
      let uinfo=JSON.parse(localStorage.getItem('uinfo'))
      if(!uinfo['リテールマーケット']){
        this.no_access=1;
      }else{
          this.no_access=0;
      }
    }else{
      this.no_access=1;
    }
    
    this.area=this.$route.query.area!=undefined  ? this.$route.query.area:'';
    this.parent_area=this.$route.query.parent_area!=undefined  ? this.$route.query.parent_area:'';
    this.child_area=this.$route.query.child_area!=undefined  ? this.$route.query.child_area:'';
    this.area_name_en=this.$route.query.area_name_en!=undefined  ? this.$route.query.area_name_en:'yokohama';
    this.area_name_en2=this.$route.query.area_name_en2!=undefined  ? this.$route.query.area_name_en2:'yokohama';
    this.area_name_jp=this.$route.query.area_name_jp!=undefined  ? this.$route.query.area_name_jp:'yokohama';
    this.area_name=this.cur_lan=='jp' ? this.area_name_jp:this.area_name_en2;
    this.child_area_name_en=this.$route.query.child_area_name_en!=undefined  ? this.$route.query.child_area_name_en:'yokohama';
    this.frame_id=0;
    this.getRSQuarterlyReports();
    
  }
},

  methods: {
    trimname(str){
      str=str.replace('_下限','').replace('_上限','').replace('_Upper','').replace('_Lower','');// delete _下限 _上限 _Upper _Lower
      str=str.replace('_LMT(More than one year old)','').replace('_LMT (Overall)','').replace('_LMT（全体）','').replace('_LMT（築1年以上）','');// delete _LMT (築1年以上) _LMT (全体) _Upper _Lower
      return str;
    },
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        if (window.google) {
          return resolve(window.google);
        }
        const script = document.createElement("script");
        script.src =
          "https://maps.googleapis.com/maps/api/js?key=" +
          process.env.VUE_APP_GOOGLE_KEY +
          "&callback=initMap&libraries=geometry";
        const head = document.querySelector("head");
        if (!head) return reject(new Error("head node is undefined"));
        head.appendChild(script);
        window.initMap = () => {
          resolve(window.google);
        };
        setTimeout(() => {
          if (!window.google) {
            reject(new Error("failed load google api"));
          }
        }, 5000);
      });
    },
  
    initMaps(mapd,coordinatesData,Polygon) {
     
      const strokeColors = ["#2e344e","#d18802", "#2bb4af", "#5b18c2", "#acce87", "#007cf5", "#ffe303", "#ff0352", "#00ff00", "#ff00f5", "#ffacb5"];
      const fillColors = ["#2e344e","#d18802","#2bb4af", "#5b18c2","#acce87","#007cf5","#ffe303","#ff0352","#00ff00","#ff00f5","#ffacb5",];

      const coordDate = JSON.parse(coordinatesData);
      const coordinates = coordDate;
      for(var d in coordinates){
        for(var dd in coordinates[d]){
          coordinates[d][dd].lat=parseFloat(coordinates[d][dd].lat);
          coordinates[d][dd].lng=parseFloat(coordinates[d][dd].lng);
        }
      }
      console.log(coordinates[0][0])
      mapd.setCenter(coordinates[0][0]);
      
      for (let index = 0; index < coordinates.length; index++) {

          const drawPolygon = new Polygon({
              paths: coordinates[index],
              strokeColor: strokeColors[index],
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: fillColors[index],
              fillOpacity: 0.35,
          });

          drawPolygon.setMap(mapd);
          
      }
      return mapd;
    
  },
    initializeMap(mapname) {
      if(this.google.maps.Map.length==0){
        console.log('not load');
        return false;
      }
      var _that=this;
      var mapList=[];
      var curid=0;
      if(mapname=="mfp"){
        mapList=_that.listDataMFP
        curid=0
      }else{
        mapList=_that.listDataMNSP
        curid=1
      }
      //this.$el.querySelector("#map_"+mapname).innerHTML="";
      this.mapContainer[curid] = document.querySelector("#map_"+mapname);
      
      const { Map, Marker,MapTypeId,InfoWindow,Polygon} = this.google.maps;
      //const { Map, Marker,MapTypeId,InfoWindow} = this.google.maps;
    
      
      var cgps=[0,0];
      var zoom=11;
      if(mapList.length>0){
          var i=0;
          mapList.map((obj) => {
            if(parseFloat(obj[8])>0 && parseFloat(obj[7])>0){
              i++;
              cgps[0]+=parseFloat(obj[8]);
              cgps[1]+=parseFloat(obj[7]);
            }
          })

      // map create
      
      _that.map[curid] = new Map(this.mapContainer[curid], {
          zoom: zoom,
          scrollwheel: false,
          disableDoubleClickZoom:true,
          zoomControl: false,
          mapTypeId:  MapTypeId.ROADMAP,
        });
        
      if(this.chart_polygon!=null && this.chart_polygon.length>100){
        _that.map[curid]=this.initMaps(_that.map[curid],this.chart_polygon,Polygon);

      }else{
        // set locate
          _that.map[curid].setCenter({
            lat: parseFloat((cgps[0]/i).toFixed(5)),
            lng: parseFloat((cgps[1]/i).toFixed(5)),
          });
      }
      //new Geocoder.geocode({ address: _that.area_name }, function (results, status) {
      //  if (status === "OK" && results[0]) {
       
          _that.markers[curid]=[];
          _that.infowindow[curid]=[];
          
            mapList.map((obj,i) => {
              if(parseFloat(obj[8])>0 && parseFloat(obj[7])>0){
                _that.infowindow[curid][i] = new InfoWindow({
                  content: '<div id="content">' +
                              '<div id="siteNotice">' +
                              "</div>" +
                              '<h3 id="firstHeading" class="firstHeading">'+(_that.cur_lan!='en' ?obj[0]:obj[1])+'</h3>' +
                              '<div id="bodyContent">' +
                              "<p>"+_that.lan.location+" ："+(_that.cur_lan!='en' ?obj[2]:obj[3])+"<br>"+_that.lan.completion+" ："+obj[4]+"<br>"+_that.lan.gfa_+" ："+(isNaN(obj[6])==false ? _that.formart_money(obj[6]):obj[6])+"</p>" +
                              "</div>" +
                              "</div>"
                });
                _that.markers[curid][i]=new Marker({
                  position: {
                            lat: parseFloat(obj[8]),
                            lng: parseFloat(obj[7])},
                  map:_that.map[curid],
                  title: (_that.cur_lan!='en' ?obj[0]:obj[1]),
                  icon: "https://cdn2.cbredata.com/images/lou.png",
                });
                _that.markers[curid][i].addListener("click", () => {
                  _that.infowindow[curid][i].open({
                    anchor: _that.markers[curid][i],
                    map:_that.map[curid],
                    shouldFocus: false
                  });
                });
              }
            });      
      }else{
        
        _that.map[curid] = new Map(this.mapContainer[curid], {
          zoom: 11,
          mapTypeId:  MapTypeId.ROADMAP,
        });
        
     
        // set locate
          _that.map[curid].setCenter({
            lat: parseFloat((_that.current_gps.split(',')[0]).toFixed(5)),
            lng: parseFloat((_that.current_gps.split(',')[1]).toFixed(5)),
          });
      
      }           
        
    },
    showpop:function(curn){
      this.isPopup[curn]=1;
    },
    loadiframe(type){
     
        if(type!=this.frame_id){
          this.frame_id=type;
              var that = this;
          axios.get('getRSQuarterlyReports', {
            params: {
              'beginQuarter': this.beginQuarter,
              'endQuarter': this.endQuarter,
              'child_area': this.child_area,
              'parent_area': this.parent_area,
              'area': this.area,
              'area_name': this.area_name_en,
              'area_name_jp': this.area_name_jp,
              'child_area_name': this.child_area_name_en,
              'showtype':'chart',
              //'area_map_name': this.child_area_name_en!='' ? this.child_area_name_en.replace(new RegExp(" ","g"),'_'):this.area_name_en.replace(new RegExp(" ","g"),'_'),     
            }
          }).then(function (response) {
            that.isLoading = false;
            if (response.data.status) {
             
              that.chart_rs_vacancy_rate = response.data.data.chart_rs_vacancy_rate;
              that.chart_rs_rent = response.data.data.chart_rs_rent;
              if (that.frame_id == 1) {
                  that.cur_iframe = that.chart_rs_vacancy_rate[that.cur_lan];
              } else if (that.frame_id == 2) {
                that.cur_iframe = that.chart_rs_rent[that.cur_lan];
              }
            } else {
              that.isLoading = false;
            }
          });
        }
        
    },
    get_options(objs,headers){
      var that=this;
     return {chart: {
                  type: objs.title['jp'].indexOf('空室率')>-1 || objs.title['jp'].indexOf("プライムイールド") ? 'line':'bar',
                  stacked: false,
                  height:650,
                  width:800,
                  zoom: {
                    enabled: false
                  },
                  toolbar: {
                    show: false,
                  },
                 
                },
                dataLabels: {
                  enabled: false
                },
                yaxis: {  
                  min: function (min) { return min < -1000 ? (Math.floor(min / 1000)) * 1000 : Math.floor(min / 10) * 10 },
                  max:function(max) {  return max>1000 ? (Math.ceil(max/1000))*1000:Math.ceil(max/10)*10 },       
                  labels: {
                    formatter: function (value) {
                      return that.formart_money(value,objs.title['jp'].indexOf('空室率')>-1 || objs.title['jp'].indexOf('プライムイールド')>-1 ?'':'int');
                    }
                  },
                },
                colors: ['#80bbad', '#dbd99a','#d2785a', '#a388bf','#3e7ca6'],
             annotations: {
          xaxis: [
          {
            x: this.dateformat("start_out_quarter"),
            x2: this.dateformat("end_quarter"),
            fillColor: '#CAD1D3',
            opacity: 0.4,
            label: {
              
              borderColor: '#CAD1D3',
              style: {
                fontSize: '10px',
                color: '#fff',
                background: '#00E396',
              },
              offsetY: -10,
              text: '',
            }
          }]
        },
              
              title: {
                text: that.trimname(objs.title[this.cur_lan]),
                align: 'left'
              },
              grid: {
                row: {
                  colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                  opacity: 0.5
                },
              },
              xaxis: {
                title: {
                text: this.cur_lan=="en"? 'Quarter':'四半期'
                },
                labels: {
                  rotate: 315,
                  rotateAlways: true,
                },
                categories: headers,
              }
            }
           
    },
    get_series:function(ndd,title){
      var zc_total=[];
      var name=''
      var name_array=[];
      for(var d in ndd){
         if(ndd.length==2 && title.indexOf('上限')>-1){
            name_array=[this.cur_lan=="en" ? 'Upper':'上限',this.cur_lan=="en" ? 'Lower':'下限']
            name=name_array[d];
         }else{
            name=this.cur_lan=="en" ? ndd[d][0]:ndd[d][1];
          }
        var subd=[]
        for(var f in ndd[d]){
          if(f>1){
            subd.push(ndd[d][f]!='-' ? parseInt(ndd[d][f])!=ndd[d][f] ? parseFloat(ndd[d][f]).toFixed(1):ndd[d][f]:null);
          }
        }
        zc_total[d]={name: name,data:subd};
      }
      return zc_total;
    },
    dateformat:function(type){
      var myDate = new Date();
      var cur_month=myDate.getMonth()+1<10 ?myDate.getMonth()+1<7 ? myDate.getMonth()+1<4 ? '03':'06':'09':'12';
      var cur_year=myDate.getFullYear();
      var cur_quarter=""
      if(type=='start'){//before 2 years
        cur_year-=2
      }else if(type=='start_out_quarter'){
         cur_quarter=cur_year+(myDate.getMonth()+1<10 ?myDate.getMonth()+1<7 ? myDate.getMonth()+1<4 ? 'Q1':'Q2':'Q3':'Q4');
        return cur_quarter
      }else if(type=='end_quarter'){
         cur_quarter=(cur_year+2)+(myDate.getMonth()+1<10 ?myDate.getMonth()+1<7 ? myDate.getMonth()+1<4 ? 'Q1':'Q2':'Q3':'Q4');
        return cur_quarter
      } else{//after 2 years
        cur_year+=2
      }
      return cur_year+'-'+cur_month+'-'+'01';
    },
     formart_money:function (num,type='') {
      if(type!=undefined &&  type!=null && type=='int'){
        return this.formart_money_int(num);
      }
       if(isNaN(num) || num==null){
        return num;
      }
      var result = '', counter = 0;
      //if(parseInt(num) == num) return num;
      num=num.toString();
      var numa=num.split('.');
      if(numa.length>1){
        num =parseFloat(num);
        num = (num || 0);
        num=num.toFixed(1);
        numa=num.split('.');
      }
      num=numa[0]
      for (var i = num.length - 1; i >= 0; i--) {
         counter++;
        result = num.charAt(i) + result;
        if (!(counter % 3) && i != 0 && num.charAt(i-1)!='-') { result = ',' + result; }
      }
      return result+(numa[1]!=undefined && numa[1]!=0 ? '.'+numa[1]:'.0');
    },
    formart_money_int:function (num) {
      
      if(isNaN(num) || num==null || num==0){
        return num=='' || num==null ?'-':num;
      }
      var result = '', counter = 0;
      //if(parseInt(num) == num) return num;
      num=num.toString();
      var numa=num.split('.');
      num=numa[0]
      for (var i = num.length - 1; i >= 0; i--) {
         counter++;
        result = num.charAt(i) + result;
        if (!(counter % 3) && i != 0 && num.charAt(i-1)!='-') { result = ',' + result; }
      }
      return result;
    },
    downloadData:function(charset){
      if(localStorage.getItem('email')!='' && localStorage.getItem('email').indexOf('vipguest')>-1){
        alert(this.lan.gust_notice);
        return false;
      }
      location.href=axios.defaults.baseURL+'/downloadOutputMarketRSData?area='+this.area+'&area_name_en='+this.area_name_en2+'&area_name_jp='+this.area_name_jp+'&charset='+charset+'&token='+localStorage.getItem('id_token')+'&active_email='+localStorage.getItem('email')+'&rnd='+(new Date().getTime());
    },
    getRSQuarterlyReports:function (){
      
      this.isLoading=true;
      var that=this;
      axios.get('getRSQuarterlyReports', {
        params: {
          'beginQuarter': this.beginQuarter,
          'endQuarter': this.endQuarter,
          'child_area': this.child_area,
          'parent_area': this.parent_area,
          'area': this.area,
          'area_name': this.area_name_en,
          'area_name_jp':this.area_name_jp,
          'child_area_name': this.child_area_name_en,
          //'area_map_name': this.child_area_name_en!='' ? this.child_area_name_en.replace(new RegExp(" ","g"),'_'):this.area_name_en.replace(new RegExp(" ","g"),'_'),     
           }
      }).then(function (response) {
            that.isLoading=false;
            if(response.data.status){
              that.tableHeaders = response.data.data.headers;
              that.listData = response.data.data.data;
              that.chart_rs_vacancy_rate=response.data.data.chart_rs_vacancy_rate;
              that.chart_rs_rent=response.data.data.chart_rs_rent;
              that.cur_iframe=that.chart_rs_vacancy_rate[that.cur_lan];
              that.area_map=response.data.data.chart_area_map;
              that.chart_polygon=response.data.data.chart_polygon;
              that.area_description=response.data.data.area_description;
              that.cur_quarter_index=response.data.data.cur_quarter_index;
              that.creis_cur_quarter=response.data.data.creis_cur_quarter;
              that.creis_area_total=response.data.data.creis_area_total;
              that.current_gps=response.data.data.current_gps;

              that.getRSFutureSupplyProject();
              that.getRSNewSupplyProject();
              let odd=response.data.data.data;
              
              var nemp=0;
              var ndd={};
              var nndd=[];
              var n=0;
           
              
              for(var i in odd){
                
                ndd['k'+n]={title:odd[i].title,data:[]};
             
                for(var f in odd[i].data){
                  nemp=0;
                  odd[i].data[f][1]=odd[i].data[f][1].replace("Grade","グレード");
                   for(var ff in odd[i].data[f]){
                     
                      if(ff>1){
                        
                        if(odd[i].data[f][ff]!='-' && odd[i].data[f][ff]!=null){
                          nemp+=1;
                        }
                      }
                      
                   }
                   
                   if(nemp>0){
                      console.log(i+'d'+f)
                      ndd['k'+n].data.push(odd[i].data[f]);  
                   }
                  
                }
                if(ndd['k'+n].data==null || ndd['k'+n].data.length==0){
                  delete ndd['k'+n];
                }else{
                  n+=1;
                }
                
              }
              
              for(var s in ndd){
                nndd.push(ndd[s]);
              }
              that.listData=nndd;
              console.log(that.listData);
              that.show_rate=0;
              for(var d in that.listData){
                d=parseInt(d);
                that.listData[d].chart_options=that.get_options(that.listData[d], that.tableHeaders);
                that.isPopup.push(0);
                if(that.listData[d].title['jp'].indexOf('空室率')>-1){
                   that.show_rate=1;
                   
                }
                if(that.listData[d].title['jp'].indexOf('プライムイールド(％)_上限')>-1 || that.listData[d].title['jp'].indexOf('プライム賃料(円/坪)_上限')>-1){
                  
                  let st=that.listData[d].data[1] !=undefined ? that.listData[d].data[1]:that.listData[d].data[0];
                  let et=[]
                  if(that.listData[d+1]!=undefined){
                    et=that.listData[d+1].data[1] !=undefined ? that.listData[d+1].data[1]:that.listData[d+1].data[0];
                  }else if(that.listData[d-1]!=undefined){
                    
                    et=that.listData[d-1].data[1] !=undefined ? that.listData[d-1].data[1]:that.listData[d-1].data[0];
                  
                  }
                  st[1]='上限';
                  et[1]='下限';
                 
                  console.log([st,et])
                  that.listData[d].chart_series=that.get_series([st,et],that.listData[d].title['jp']);
                }else{
                  that.listData[d].chart_series=that.get_series(that.listData[d].data,that.listData[d].title['jp']);
                }
                
                //that.listData[d].data=odd[d].data;
              }
              if(!that.show_rate){
                that.loadiframe(2);
              }
              console.log(that.listData);
              that.error_msg="";
            }else{
              that.suc_msg="";
              that.error_msg=response.data.msg;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getRSFutureSupplyProject:function (){
      
      this.isLoading=true;
      var that=this;
      axios.get('getRSFutureSupplyProject', {
        params: {
          'area': this.area_name_jp
        }
      }).then(function (response) {
            that.isLoading=false;
            if(response.data.status){
              that.listDataMFP = response.data.data.data;
              setTimeout(function(){
                that.initializeMap('mfp');
              },3000)
              that.error_msg="";
              
            }else{
              that.suc_msg="";
              that.error_msg=response.data.msg;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }, getRSNewSupplyProject:function (){
      
      this.isLoading=true;
      var that=this;
      axios.get('getRSNewSupplyProject', {
        params: {
          'area': this.area_name_jp
        }
      }).then(function (response) {
            that.isLoading=false;
            if(response.data.status){
              that.listDataMNSP = response.data.data.data;
              setTimeout(function(){
                that.initializeMap('mnsp');
              },3000)
              
              that.error_msg="";
            }else{
              that.suc_msg="";
              that.error_msg=response.data.msg;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }

  },
 
}
</script>